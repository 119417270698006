.detailsPageButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0px 16px;
}

.detailsPageButtonContainer .detailsButton {
    width: 164px;
    height: 36px;
}

@media (max-width: 479px) {

    .detailsPageButtonContainer .detailsButton {
        width: 150px;
        /* width: 256px; */
    }
}