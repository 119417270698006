.alertBar {
    position: absolute;
    left: calc(50% - 256px - 16px);
    top: 0px;
    width: calc(512px + 32px);
    overflow: hidden;
}

.alertBar.expanded {
    overflow: visible;
}

.alertBarContainer {
    width: 512px;
    height: 128px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-top: calc(-128px - 32px);
    transition: margin-top .3s ease-in-out;
    padding: 16px;
}

.alertBarContainer.expanded {
    margin-top: 0px;
}

.alertBarContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px 16px;
}

.alertBarContent .alertIcon {
    height: 48px;
    width: 48px;
    margin-right: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.alertBarContent .alertTitleAndMessage {
    /* flex: 1; */
}

.alertBarContent .alertTitleAndMessage .title {
    font-family: DokomFont2;
    font-size: 20px;
}

.alertBarContent .alertTitleAndMessage .title.error {
    color: #d20000;
}

@media (max-width: 479px) {
    .alertBarContainer {
        width: calc(100vw - 32px);
    }
    .alertBar {
        position: absolute;
        left: 0px;
        top: 0px;
        width: calc(100vw);
        overflow: hidden;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .alertBarContainer {
        width: calc(100vw - 32px);
    }
    .alertBar {
        position: absolute;
        left: 0px;
        top: 0px;
        width: calc(100vw);
        overflow: hidden;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}