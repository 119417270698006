.contextBoxText {
    padding: 20px
}

.cancelButton {
    margin-top: 10px;
}

.dialogBox {
    max-width: 400px;
    margin: auto;
}

.dialogOverlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
}


.dialogBoxButtons {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
}

.dialogBox.orderTurboDialogContainer {
    min-width: 748px;
    width: 748px;
    max-width: 748px;
}

@media (max-width: 479px) {
    .dialogBox.orderTurboDialogContainer {
        min-width: 100%;
        max-width: 100vw;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .dialogBox.orderTurboDialogContainer {
        min-width: 80%;
        max-width: calc(100vw -128px);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .dialogBox.orderTurboDialogContainer {
        min-width: 80%;
        max-width: calc(100vw -128px);
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}