.linkWithArrow {
    display: inline;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}

.linkWithArrow .linkWithArrowChildren {
    display: inline-block;
}

.linkWithArrow .linkWithArrowIcon {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 4px;
}