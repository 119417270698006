.selectedOrderContainer {}

.turboOfferContractInfoContainer {
    padding: 8px 0px;
}

.turboOfferContractInfoContainer,
.turboOfferContractInfoContainer .innerData,
.turboOfferContractInfoContainer .innerData .customerInfo,
.turboOrderSpeedInfoContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.turboOfferContractInfoContainer .innerData .customerInfo {
    align-items: center;
}

.turboOfferContractInfoContainer .innerData .customerInfo .customerIcon {
    height: 40px;
    width: 40px;
    background-position: center;
    background-size: 36px;
    background-repeat: no-repeat;
    margin-right: 4px;
}

.turboOfferContractInfoContainer .innerData .customerInfo .customerData {
    padding: 0px 8px;
}

.turboOfferContractInfoContainer .contractData,
.turboOfferContractInfoContainer .durationData,
.turboOrderSpeedInfoContainer .downlaodInfo,
.turboOrderSpeedInfoContainer .uploadInfo {
    flex: 1 1 268px;
}

.turboOfferContractInfoContainer .innerData .customerInfo,
.turboOfferContractInfoContainer .innerData .customerAdress {
    flex: 1;
}

.legalCheckboxesContainer {}

.legalCheckboxesContainer .readTermsAndConditions,
.legalCheckboxesContainer .confirmPrivacy {
    padding: 4px 0px;
}

.contentBoxSaveButtonsContainer.orderButtonsContainer {
    padding-top: 16px;
    flex-wrap: wrap;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}