.overviewPageContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.currentRateAndHistoryContainer {
    flex: 1 1 320px;
    flex-wrap: wrap;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
}

.offerSection {
    flex: 1 1 calc(480px);
}

.offerSection .pricingConditionsAndBusinessTermsContainer {
    padding: 0px 16px 8px;
    color: #ffffff;
    font-size: 14px;
    font-family: DokomFont2;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {
    .currentRateAndHistoryContainer {
        display: block;
    }
}

@media (min-width: 1920px) {
    .currentRateAndHistoryContainer {
        display: block;
    }
}