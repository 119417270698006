.ttButton.disabled {
    cursor: auto;
    background-color: rgba(255, 255, 255, .3);
    border: rgba(255, 255, 255, .3) 1px solid;
}

.ttButton.orange.disabled {
    background-color: rgba(243, 138, 50, .3);
    border: rgba(243, 138, 50, .0) 1px solid;
}

.ttButton:not(.disabled):hover,
.ttButton:not(.disabled):active {
    /* whoa we need a hover effect */
    /* color: #269ed2; */
    color: #000;
    text-decoration: none;
}

.ttButton.orange:not(.disabled):hover {
    color: #ffffff;
    background-color: #269ed2;
    border: #ffffff 1px solid;
    text-decoration: none;
}

.ttButton.maxHeight {
    height: 100%;
}

.ttButton {
    color: #269ed2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    cursor: pointer;
    border-radius: 50px;
    border: #269ed2 1px solid;
    background-color: #ffffff;
    text-decoration: none;
}


.ttButton.whiteBorder {
    border: #ffffff 1px solid;
}

.ttButton.orange {
    color: #ffffff;
    border: #f38a32 1px solid;
    background-color: #f38a32;
}

@media (max-width: 479px) {
    .ttButton {
        height: 32px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}