.ttAppContent {
    height: 100%;
    /* Take available Space! */
    flex: 1;
    position: relative;
    display: table-cell;
    width: 100%;
    vertical-align: top;
}

.ttAppContentChildren {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.ttAppContentBackgroundContainer {
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #ffffff;
}

.ttAppLogoIcon {
    z-index: 100;
    position: absolute;
    top: calc(16px + 2vw);
    right: calc(16px + 2vw);
}

.ttAppContentBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -2px;
    background-position-y: 2px;
    position: absolute;
    border-bottom: #269ed2 2px solid;
    top: 112px;
    left: 0px;
    right: 0px;
    height: 12vw;
}

.ttAppContentBackgroundBlue {
    position: absolute;
    background-color: #269ed2;
    top: calc(112px + 12vw);
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.contentChildrenBackground {
    position: absolute;
    z-index: -1;
    background-color: #269ed2;
    left: 0px;
    right: 0px;
}

@media (max-width: 479px) {
    .ttAppContent {
        overflow: inherit !important;
        display: block;
    }
    .contentChildrenBackground {
        top: calc(112px - 140px + 12vw);
        height: calc(100% + 140px - 112px - 12vw);
    }
    .ttAppContentChildren {
        /* top: 140px; */
        padding: 0px;
    }
    .ttAppContentSpacer {
        width: 100%;
        height: 140px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .contentChildrenBackground {
        top: calc(112px - 220px + 12vw);
        height: calc(100% + 220px - 112px - 12vw);
    }
    .ttAppContentChildren {
        /* top: 220px; */
        padding: 0px 8px 0px 8px;
    }
    .ttAppContentSpacer {
        width: 100%;
        height: 220px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contentChildrenBackground {
        top: calc(112px - 220px + 12vw);
        height: calc(100% + 220px - 112px - 12vw);
    }
    .ttAppContentChildren {
        /* top: 220px; */
        padding: 0px 36px 0px 88px;
    }
    .ttAppContentSpacer {
        width: 100%;
        height: 220px;
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {
    .contentChildrenBackground {
        top: calc(112px - 240px + 12vw);
        height: calc(100% + 240px - 112px - 12vw);
    }
    .ttAppContentChildren {
        /* top: 240px; */
        padding: 0px 48px 0px 88px;
    }
    .ttAppContentSpacer {
        width: 100%;
        height: 240px;
    }
}

@media (min-width: 1920px) {
    .contentChildrenBackground {
        top: calc(112px - 240px + 12vw);
        height: calc(100% + 240px - 112px - 12vw);
    }
    .ttAppContentChildren {
        /* top: 240px; */
        padding: 0px 380px 0px 320px;
    }
    .ttAppContentSpacer {
        width: 100%;
        height: 240px;
    }
    .ttAppContentBackgroundImage {
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: -2px;
        background-position-y: 2px;
        position: absolute;
        border-bottom: #269ed2 2px solid;
        top: 112px;
        left: 0px;
        right: 0px;
        height: 12vw;
    }
}