body {
    font-family: DokomFont1;
    font-size: 16px;
    margin: 0px !important;
    padding: 0px !important;
    background: #269ed2;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}