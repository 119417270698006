@font-face {
    font-family: 'DokomFont1';
    src: local('2B6D9B_0_0'), url(./../sharedAssets/Fonts/2B6D9B_0_0.woff) format('woff');
}

@font-face {
    font-family: 'DokomFont2';
    src: local('2B6D9B_1_0'), url(./../sharedAssets/Fonts/2B6D9B_1_0.woff) format('woff');
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}