.checkboxWithLabelContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.checkboxWithLabelContainer .checkboxWithLabelCheckbox {
    min-height: 16px;
    min-width: 16px;
    display: block;
    margin-right: 4px;
}

.checkboxWithLabelContainer .checkboxWithLabelLabel {
    display: block;
    margin-top: 2px;
}