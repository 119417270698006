.divWithArrow {
    display: inline;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: #269ed2;
    text-decoration: none;
}

.divWithArrow.white {
    color: #ffffff;
}
.divWithArrow.disabled {
    opacity: .6;
}

.divWithArrow:not(.disabled):hover {
    cursor: pointer;
    text-decoration: underline;
}

.divWithArrow .divWithArrowChildren {
    display: inline-block;
}

.divWithArrow .divWithArrowIcon {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 4px;
}