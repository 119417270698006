.contentBoxWrapper {
    margin: 16px;
    flex: 1 1 calc(480px);
}

.contentBoxWrapper.colSpan2 {
    width: calc(100% - 32px);
}

.contentBoxContainer {
    min-width: 24px;
    min-height: 24px;
    border-radius: 6px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}

.contentBoxContentContainer {
    padding: 8px 36px 36px;
}

@media (max-width: 479px) {
    .contentBoxContainer {
        min-width: 24px;
        min-height: 24px;
        /* border-radius: 0px; */
    }
    .contentBoxWrapper {
        margin: 16px 4px;
        flex: 1 1 calc(320px);
    }
    .contentBoxContentContainer {
        padding: 8px 16px 36px;
    }
    .contentBoxWrapper.colSpan2 {
        width: calc(100% - 32px);
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .contentBoxWrapper.colSpan2 {
        width: calc(100% - 32px);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contentBoxWrapper.colSpan2 {
        width: calc(100% - 32px);
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}