.appNavigationContainer {
    position: relative;
    background-color: #fff;
    display: table-cell;
    vertical-align: top;
}

.appNavigationContainer.hidden {
    display: none;
}

.burgerMenuIconContainerWrapper {
    position: relative;
    z-index: 0;
    padding: 32px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.burgerMenueContentHeader {
    padding: 32px 16px 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.burgerMenuIconContainer {}

.burgerMenuIconContainer:hover {
    cursor: pointer;
}

.burgerMenueMenueContentWrapper {
    width: 320px;
    position: absolute;
    z-index: 100;
    top: 0px;
    left: -320px;
    /* bottom: 0px; */
    min-height: 100%;
    padding-bottom: 16px;
    border-right: #269ed2 1px solid;
    background-color: #ffffff;
    transition: .25s ease-in-out;
    /* transform: scaleX(0.0); */
    transform-origin: left;
}

.burgerMenueMenueContentWrapper.collapsed {
    position: absolute;
    opacity: 0;
    display: none;
}

.burgerMenueMenueContentWrapper.expanded {
    /* transform: scaleX(1.0); */
    left: -0px;
}

.burgerMenueMenueMobileContentWrapper {
    display: none;
}

@media (max-width: 479px) {
    .appNavigationContainer {
        display: block;
    }
    .burgerMenuIconContainerWrapper {
        padding: 16px 16px 8px;
        width: calc(100% - 32px);
        justify-content: flex-end;
    }
    .burgerMenueMenueMobileContentWrapper {
        display: block;
        position: relative;
        z-index: 100;
        top: 0px;
        left: 0px;
        right: 0px;
        border-bottom: #269ed2 1px solid;
        background-color: #ffffff;
        transition: .25s ease-in-out;
        transform: scaleY(0.0);
        transform-origin: top;
    }
    .burgerMenueMenueMobileContentWrapper.collapsed {
        display: none;
        opacity: 0;
    }
    .burgerMenueMenueMobileContentWrapper.expanding {
        display: block;
    }
    .burgerMenueMenueMobileContentWrapper.expanded {
        transform: scaleY(1.0);
    }
    .burgerMenueMenueContentWrapper {
        display: none;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .appNavigationContainer {
        border-right: #269ed2 1px solid;
        min-width: 40px;
        min-height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .appNavigationContainer {
        border-right: #269ed2 1px solid;
        min-width: 40px;
        min-height: 100%;
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {
    .appNavigationContainer {
        border-right: #269ed2 1px solid;
        min-width: 40px;
        min-height: 100%;
    }
}

@media (min-width: 1920px) {
    .appNavigationContainer {
        border-right: #269ed2 1px solid;
        min-width: 60px;
        min-height: 100%;
    }
}