.userInfoContainerWrapper {
    padding: 0px 0px 32px;
}

.userInfoContainer .userIconContainer {
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.userInfoContainer .fullName {
    width: 100%;
    text-align: center;
    font-family: DokomFont2;
    font-size: 18px;
    padding: 8px 0px 4px;
}

.userInfoContainer .customerNumber {
    width: 100%;
    text-align: center;
    padding: 4px 0px 8px;
}

.userInfoContainer .logoutLink {
    padding-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: DokomFont2;
    font-size: 18px;
}

.userInfoContainer .logoutLink .logoutIcon {
    margin-right: 8px;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}