.burgerMenueLinks .burgerMenueLinksHeader {
    padding: 32px 0px 32px 32px;
    font-family: DokomFont2;
    font-size: 20px;
}

.burgerMenueLinks .menueSplitter {
    margin: 16px 32px;
    width: calc(100% - 64px);
    border-bottom: solid 1px #cbcbcb;
}