.currentRateBoxContent {
    min-height: 20px;
}

.currentRateBoxContent .productDataContainer .productName {
    padding: 8px 0px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.currentRateBoxContent .productDataContainer .productName {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.productNameAdditionalInfo {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.showDetailsContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.productNameLabel {
    flex: 1;
}

.currentRateBoxContent .productDataContainer .productValidUntil {
    font-size: 12px;
}

.bandwithInfoContainer {
    padding: 8px 0px;
}

.bandwithInfoContainer .downloadInfo,
.bandwithInfoContainer .uploadInfo {
    padding: 8px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.downloadInfo .downloadInfoIcon,
.uploadInfo .uploadInfoIcon {
    height: 24px;
    width: 24px;
    margin: 4px 4px 4px 0px;
    background-position: 4px 4px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
}

.uploadInfoContent,
.downloadInfoContent {
    flex: 1;
}

.uploadInfoContent .label,
.downloadInfoContent .label {
    padding: 4px 0px;
}

@media (max-width: 479px) {
    .hiddenSmartphone {
        display: none;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .hiddenNonSmartphone {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hiddenNonSmartphone {
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {
    .hiddenNonSmartphone {
        display: none;
    }
}

@media (min-width: 1920px) {
    .hiddenNonSmartphone {
        display: none;
    }
}