pre{
    font-family: DokomFont1;
    white-space: normal;
}
.visibleOnlyXS {
    display: none;
}

.ttLink:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ttLink {
    text-decoration: none;
}

.orange {
    color: #f38a32;
}

.blue,
.ttLink.blue {
    color: #269ed2;
}

.defaultPageContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

a {
    color: #269ed2;
    text-decoration: none;
}

a:not(.disabled):hover {
    text-decoration: underline;
}

a.white {
    color: #ffffff;
}

.normal {
    font-family: DokomFont1;
}

.strong {
    font-family: DokomFont2;
}

@media (max-width: 479px) {
    .hiddenXS {
        display: none;
    }
    .visibleOnlyXS {
        display: block;
        display: initial;
    }
}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}