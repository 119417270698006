.ttCheckboxInput {
    -webkit-appearance: unset;
    /* border-radius: 2px; */
    /* border: #269ed2 1px solid; */
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: 16px;
    background-repeat: no-repeat;
}

.inlineCheckboxContainer {
    display: inline-block;
    vertical-align: middle;
}