.turboHistoryOverview.turboEntry {
    width: 100%;
}

.turboHistoryOverview.turboEntry .turboEntryContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
}

.turboHistoryOverview.turboEntry .turboEntryContent .orderExpires,
.turboHistoryOverview.turboEntry .turboEntryContent .orderBandwidth,
.turboHistoryOverview.turboEntry .turboEntryContent .orderTurboDuration,
.turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
    padding: 4px 8px;
}

.turboHistoryOverview.turboEntry .turboEntryContent .orderExpires {
    flex: 2;
    text-align: right;
}

.turboHistoryOverview.turboEntry .turboEntryContent .orderBandwidth,
.turboHistoryOverview.turboEntry .turboEntryContent .orderTurboDuration,
.turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
    white-space: nowrap;
    flex: 1;
    width: 128px;
}
.turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
    flex: 2;
    width: 128px;
}
.turboHistoryOverview.turboEntry .turboEntryContent .orderTurboDuration {
    text-align: right;
}

.linkToTurboHistoryDetails {
    padding-top: 16px;
}

@media (max-width: 479px) {
    .turboHistoryOverview.turboEntry .turboEntryContent .orderBandwidth {
        text-align: center;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderTurboDuration,
    .turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
        width: calc(50% - 64px);
    }
    .turboHistoryOverview.turboEntry .turboEntryContent {
        flex-wrap: wrap;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .separator {
        width: 100%;
        height: 16px;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderExpires {
        flex: 1 1 100%;
        text-align: right;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
        flex: 2;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .turboHistoryOverview.turboEntry .turboEntryContent {
        flex-wrap: wrap;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .separator {
        width: 100%;
        height: 16px;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderExpires {
        flex: 1 1 100%;
        text-align: right;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
        flex: 2;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .turboHistoryOverview.turboEntry .turboEntryContent {
        flex-wrap: wrap;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .separator {
        width: 100%;
        height: 16px;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderExpires {
        flex: 1 1 100%;
        text-align: right;
    }
    .turboHistoryOverview.turboEntry .turboEntryContent .orderDate {
        flex: 2;
    }
}