.badgeContainer {
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    font-family: DokomFont2;
}

.badgeContainer.orange {
    color: #ffffff;
    background-color: #f38a32;
}
.badgeContainer.blue {
    color: #ffffff;
    background-color: #269ed2;
}
.badgeContainer.clickable {
    cursor: pointer;
    margin-left: auto;
}