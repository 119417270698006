.contentBoxTitleWrapper {
    padding: 0px 36px;
}

.contentBoxTitleContainer {
    padding: 20px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: solid 1px #d8d8d8;
}

.contentBoxTitleContainer .icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    margin-right: 20px;
}

.contentBoxTitleContainer .label {
    font-family: DokomFont2;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

@media (max-width: 479px) {
    .contentBoxTitleWrapper {
        padding: 0px 16px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}