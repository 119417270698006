.loginForm {
    min-width: 320px;
}

.loginForm .loginButton {
    margin-top: 16px;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}