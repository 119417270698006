.formInputGroup {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 0px;
}

.formInputGroup.vertical {
    display: block;
}

.formInputGroup.vertical .inputLabelContainer {
    padding: 0px 0px 4px;
}

.formInputGroup.vertical .inputContainer {
    padding: 0px;
    width: 100%;
}

.formInputGroup:not(.vertical) .inputLabelContainer {
    width: 150px;
    display: flex;
    justify-content: flex-end;
    padding: 0px 8px;
}

.formInputGroup .inputContainer {
    flex: 1;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}