.bandwidthSpeedInfoCurve {}


.bandwidthSpeedInfoCurve .infoLabelContainer,
.bandwidthSpeedInfoCurve .infoLabelContainer {
    padding: 8px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bandwidthSpeedInfoCurve .infoLabelContainer .infoIcon,
.bandwidthSpeedInfoCurve .infoLabelContainer .infoIcon {
    height: 24px;
    width: 24px;
    margin: 4px 4px 4px 0px;
    background-position: 4px 6px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
}

.bandwidthSpeedInfoCurve .speedInfoInnerLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -32px;
    font-family: DokomFont2;
    font-size: 24px;

}
.bandwidthSpeedInfoCurve .speedInfoInnerLabel .currentWithTurbo{
    font-size: 16px;
    margin-top: 4px;
}

.bandwidthSpeedInfoCurve .infoLabelContainer .infoLabel,
.bandwidthSpeedInfoCurve .infoLabelContainer .infoLabel {}