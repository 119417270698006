.orderPage {
    width: 100%;
}

.selectedOrderContainer {}

.turboDetailsCustomerInfoContainer {
    padding: 8px 0px;
}

.turboDetailsCustomerInfoContainer,
.turboDetailsCustomerInfoContainer .innerData,
.turboDetailsCustomerInfoContainer .innerData .customerInfo,
.turboOrderSpeedInfoContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.turboDetailsCustomerInfoContainer .innerData .customerInfo {
    align-items: center;
}

.turboDetailsCustomerInfoContainer .innerData .customerInfo .customerIcon {
    height: 40px;
    width: 40px;
    background-position: center;
    background-size: 36px;
    background-repeat: no-repeat;
    margin-right: 4px;
}

.turboDetailsCustomerInfoContainer .innerData .customerInfo .customerData {
    padding: 0px 8px;
}

.turboDetailsCustomerInfoContainer .contractData,
.turboDetailsCustomerInfoContainer .durationData,
.turboOrderSpeedInfoContainer .downlaodInfo,
.turboOrderSpeedInfoContainer .uploadInfo {
    flex: 1 1 268px;
}

.turboDetailsCustomerInfoContainer .innerData .customerInfo {
    flex: 1;
}

.legalCheckboxesContainer {}

.legalCheckboxesContainer .readTermsAndConditions,
.legalCheckboxesContainer .confirmPrivacy {
    padding: 4px 0px;
}

.contentBoxSaveButtonsContainer.orderButtonsContainer {
    padding-top: 16px;
    flex-wrap: wrap;
}

.detailsInfoCell {
    align-items: flex-end;
}

@media (min-width: 479px) {
    .detailsInfoCell {
        flex: 1;
        align-items: flex-start;
    }

    .detailsInfoCell.value {
        flex: 2;
    }
}