.offersBigIcon {
    margin: 16px 0px;
    width: 100%;
    height: 108px;
    background-size: 320px auto;
    background-repeat: no-repeat;
    background-position: center;
}

.buttonMbitsLabel {
    font-family: DokomFont1;
    font-size: 12px;
}

.turboSelectButtonContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectOfferContainer {
    padding: 16px;
}

.offerButtonsLifeOffersContainer,
.offerButtonsTurboOffersContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 48px;
}

.offerButtonsLifeOffers,
.offerButtonsTurboOffers {
    flex: 1 1 512px;
    max-width: 512px;
}

.offerPriceInfo {
    padding: 8px;
    width: 100%;
    text-align: center;
}

.orderButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderButtonContainer .orderButton {
    width: 320px;
}

@media (max-width: 479px) {

    .offerButtonsLifeOffersContainer,
    .offerButtonsTurboOffersContainer {
        padding: 8px 4px;
    }

    .orderButtonContainer .orderButton {
        width: 256px;
    }

    .selectOfferContainer {
        padding: 16px 0px;
    }

    .offersBigIcon {
        background-size: 240px auto;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .offersBigIcon {
        width: 164px;
        height: 88px;
        background-size: 200px auto;
    }

    .selectOfferContainer {
        width: calc(100% - 164px - 32px);
        min-width: 400px;
    }

    .availableTurbosContentBox {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .offersBigIcon {
        width: 192px;
        height: 88px;
        background-size: 200px auto;
    }

    .selectOfferContainer {
        width: calc(100% - 192px - 32px);
        min-width: 400px;
    }

    .availableTurbosContentBox {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {
    .offersBigIcon {
        height: 164px;
    }
}

@media (min-width: 1920px) {
    .offersBigIcon {
        height: 164px;
    }
}