.ttAppContainer {
    position: relative;
    height: 100vh;
    /* overflow: hidden !important; */
    /* background-color: #269ed2; */
    /* overflow: auto !important; */
    background-color: #269ed2;
}


.ttAppLayout {
    position: absolute;
    top: 0px;
    left: 0px;
    /* overflow: auto !important; */
    width: 100%;
    height: 100vh;
}

@media (max-width: 479px) {
    .ttAppContainer {
        /* overflow: inherit !important; */
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .ttAppLayout {
        /* display: flex;
        justify-content: flex-start;
        align-items: flex-start; */
        display: table;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .ttAppLayout {
        /* display: flex;
        justify-content: flex-start;
        align-items: flex-start; */
        display: table;
    }
}

@media (min-width: 1024px) and (max-width: 1919px) {
    .ttAppLayout {
        /* display: flex;
        justify-content: flex-start;
        align-items: flex-start; */
        display: table;
    }
}

@media (min-width: 1920px) {
    .ttAppLayout {
        /* display: flex;
        justify-content: flex-start;
        align-items: flex-start; */
        display: table;
    }
}