.contentBoxSaveButtonsContainer {
    padding: 8px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
}

.contentBoxSaveButtonsContainer .cancelButtonContainer,
.contentBoxSaveButtonsContainer .saveButtonContainer {
    width: 320px;
}
/* 
.contentBoxSaveButtonsContainer .cancelButtonContainer {
    height: 36px;
} */

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {
    .contentBoxSaveButtonsContainer .cancelButtonContainer {
        position: absolute;
        /* top: 0px; */
        right: calc(50% + 160px);
        width: 320px;
    }
}