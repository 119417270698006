.threeValueSelectButton:not(.disabled).canSelect:hover {
    cursor: pointer;
}

.threeValueSelectButton.disabled:hover,
.threeValueSelectButton:not(.canSelect):hover {
    cursor: default;
}

.threeValueSelectButton {
    color: #269ed2;
    font-family: DokomFont2;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #269ed2;
    height: 100%;
}

.threeValueSelectButton.selected {
    background-color: #269ed2;
    color: #ffffff;
}

.threeValueSelectButton.grey .tvsButtonContent,
.threeValueSelectButton.disabled .tvsButtonContent {
    /* color: #e7e7e7; */
    opacity: .6;
}

.threeValueSelectButton.grey,
.threeValueSelectButton.disabled {
    border: solid 1px rgba(38, 158, 210, .6);
}

.threeValueSelectButton:first-child {
    border-radius: 20px 0px 0px 20px;
}

.threeValueSelectButton:last-child {
    border-radius: 0px 20px 20px 0px;
}

.threeValueSelectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
}