.burgerMenueLink {
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: rgba(255, 255, 255, 0) 5px solid;
    text-decoration: none;
}
.menueLinkContent{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.burgerMenueLink.activeRoute {
    border-left: #f38a32 5px solid;
    background-color: #fdeddf;
}

.burgerMenueLink .menueIcon {
    padding: 16px 8px 16px 28px;
    width: 40px;
    height: 40px;
    background-position: 28px 16px;
    background-size: 40px;
    background-repeat: no-repeat;
}

.burgerMenueLink .menueLabel {
    font-family: DokomFont1;
    font-size: 14px;
    color: #000000;
    font-style: normal;
    text-decoration: none;
}

.burgerMenueLink.activeRoute .menueLabel {
    font-family: DokomFont2;
}

.burgerMenueLink:hover {
    cursor: pointer;
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}