.loadingContainer {
    height: 100%;
    width: 100%;
    min-width: 48px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingContainer.hidden {
    display: none;
}

.loadingContainer.withGreyBackgroundColor {
    background-color: rgba(160, 160, 160, .6);
}

.loadingContainer.withWhiteBackgroundColor {
    background-color: rgba(255, 255, 255, .8);
}

.loadingContainer .innerContainer {
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-position: 4px;
    background-size: 40px;
    animation: loadingAnimation 2s steps(8, end) infinite;
    /* animation: loadingAnimation 1.33s linear infinite; */
}

.loadingContainer.globalLoadingIndicator {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}

@keyframes loadingAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}