.speedInfoLineBar {
    padding: 4px 0px;
    position: relative;
    width: 100%;
}

.speedInfoLineBar .backgroundLine {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    border-radius: 4px;
    border: solid 2px #efefef;
    width: 100%;
    width: calc(100% - 4px);
}

.speedInfoLineBar .progressLine {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    border-radius: 4px;
    border: solid 2px #269ed2;
    opacity: 0;
    width: 0%;
    transition: width 2s ease-in-out, opacity .1s ease-in;
    /* transform: scaleX(0.0); */
    /* transform-origin: left; */
}

.speedInfoLineBar .progressLineOrange {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    border-radius: 4px;
    border: solid 2px #f38a32;
    opacity: 0;
    width: 0%;
    transition: width 2s ease-in-out, opacity .1s ease-in;
    /* transform: scaleX(0.0); */
    /* transform-origin: left; */
}

@media (max-width: 479px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}