.speedInfoCurveArc {
    position: relative;
    width: 100%;
    width: 232px;
    height: 172px;
    overflow: hidden;
}

.speedInfoCurveArc .speedInfoCurveCanvas {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 232px;
    height: 232px;
}

.speedInfoCurveArc .speedInfoCurveCanvas.background {
    z-index: 0;
}

.speedInfoCurveArc .speedInfoCurveCanvas.orange {
    z-index: 1;
}

.speedInfoCurveArc .speedInfoCurveCanvas.blue {
    z-index: 2;
}

.speedInfoCurveArc .speedInfoCurveLabelContainer {
    z-index: 3;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 232px;
    height: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.speedInfoCurveArc .speedInfoCurveLabelContainer .speedInfoCurveLabel {
    margin-top: -32px;
    font-family: DokomFont2;
    font-size: 44px;
    color: #f38a32;
}
.speedInfoCurveArc .speedInfoCurveLabelContainer .speedInfoCurveLabel.blue {
    color: #269ed2;
}
/* 
.speedInfoCurveArc .speedInfoCurveLabelContainer .speedInfoCurveLabel.customLabel {
    font-size: 24px;
} */

.speedInfoCurveArc .backgroundCurve {
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 0;
    border-radius: 4px;
    border: solid 2px #efefef;
    width: 100%;
    width: calc(100% - 4px);
}

.speedInfoCurveArc .progressCurveOrange {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    border-radius: 4px;
    border: solid 2px #f38a32;
    opacity: 0;
    width: 0%;
    transition: width 2s ease-in-out, opacity .1s ease-in;
    /* transform: scaleX(0.0); */
    /* transform-origin: left; */
}

.speedInfoCurveArc .progressCurveBlue {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    border-radius: 4px;
    border: solid 2px #269ed2;
    opacity: 0;
    width: 0%;
    transition: width 2s ease-in-out, opacity .1s ease-in;
    /* transform: scaleX(0.0); */
    /* transform-origin: left; */
}

@media (max-width: 479px) {
    .speedInfoCurveContainer {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .speedInfoCurveContainer {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 1024px) and (max-width: 1919px) {}

@media (min-width: 1920px) {}