.settingsBoxContentContainer {}

.settingsProductUpdateAndNotificationsContainer {}

.notifyByMailContainer,
.notifyByPushServiceContainer {
    padding: 16px 0px;
}

.notifyByPushServiceContainer .notificationLevelRangeContainer.visible,
.notifyByMailContainer .notificationLevelRangeContainer.visible {
    padding: 16px 0px;
}

.notifyByPushServiceContainer .notificationLevelRangeContainer.hidden,
.notifyByMailContainer .notificationLevelRangeContainer.hidden {
    display: none;
}